import React from "react";
import PropTypes from "prop-types";
import { graphql, Link } from "gatsby";
// import { getImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import Gallery from "../components/Gallery";
import AfspraakBtn from "../components/AfspraakBtn";

export const getUrlFromImage = (image) => {
	return !!image.childImageSharp ? image.childImageSharp.fluid.src : image;
};

// eslint-disable-next-line
export const IndexPageTemplate = ({
  haarden,
  certificaten,
  fotoHans
}) => {
  // console.log(fotoHans)
  return (
    <div>
      <div className="container">
        <h1 className="title mt-6 mb-6">Gespecialiseerd in <b>onderhoud en herstellingen</b> <br/> van <b>hout- en gashaarden</b>.</h1>
        <div className="row mb-5 align-items-center">
          <div className="col-lg-4 circle-container">
            <div className="circle" style={{background: `url(${fotoHans ? getUrlFromImage(fotoHans) : ''}) top center / cover no-repeat`}}>
            </div>
          </div>
          <div className="col-lg-8 pt-3 hh-info">
            <p>Mijn naam is <b>Hans Borremans</b>. Reeds vele jaren heb ik ervaring in onderhoud en herstellingen van haarden en kachels van Barbas Bellfires. <b>Hans Haardenservice</b> is een jong en dynamisch bedrijf dat staat voor <b>kwaliteit, service en betrouwbaarheid</b>.</p>
          </div>
        </div>
        <div className="row justify-content-center">
          <AfspraakBtn />
        </div>
        <h3 className="title mt-6 mb-6">“Een haard of kachel is een echte verrijking voor uw woning. Hij geeft niet alleen een aangename warmte, maar zorgt ook voor meer sfeer en gezelligheid in huis.”</h3>
        </div>
        <section className="images">
          <Gallery images={haarden} />
        </section>
        <div className="container">
          <h2 className="title mt-6 mb-4">Onze <b>Services</b></h2>
          <div className="row justify-content-center">
            <div className="col-xl-6 col-lg-8 hh-services-item">
              <h4>Onderhoud</h4>
              <p>Jaarlijks onderhoud aan uw haard voorkomt storingen en verlengt de levensduur van uw haard.</p>
              <Link to="/onderhoud" className="nav-link meer-info" title="Onderhoud">Meer info <Arrow color={'white'} /></Link>
            </div>
          </div>
          <div className="row justify-content-center mt-3">
            <div className="col-xl-6 col-lg-8 hh-services-item">
              <h4>Reparaties & Storingen</h4>
              <p>Hans Haardenservice komt zo snel mogelijk langs om het probleem op te lossen. Zo kan u weer optimaal genieten van uw sfeervolle haard.</p>
              <Link to="/reparaties-storingen" className="nav-link meer-info" title="Reparaties & Storingen">Meer info <Arrow color={'white'} /></Link>
            </div>
          </div>

          <h2 className="title mt-6 mb-4"><b>Certificaten</b></h2>
          <div className="row justify-content-center title-info mb-5">
            <div className="col-xl-6 col-lg-8 ">
            Als gecertificieerd bedrijf kunnen wij u garanderen dat uw haard bij ons in veilige handen is.
            </div>
          </div>
        </div>
        <div className="certificaten-hh">
          <Gallery images={certificaten} />
        </div>
      </div>
  
  );
};

// IndexPageTemplate.propTypes = {
//   image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
//   title: PropTypes.string,
//   heading: PropTypes.string,
//   subheading: PropTypes.string,
//   mainpitch: PropTypes.object,
//   description: PropTypes.string,
//   intro: PropTypes.shape({
//     blurbs: PropTypes.array,
//   }),
// };

export const Arrow = (props) => (
	<svg version="1.1" className="arrow" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 36.1 25.8" enableBackground="new 0 0 36.1 25.8">
		<g>
			<line fill="none" stroke={props.color} strokeWidth="3" strokeMiterlimit="10" x1="0" y1="12.9" x2="34" y2="12.9"></line>
			<polyline fill="none" stroke={props.color} strokeWidth="3" strokeMiterlimit="10" points="22.2,1.1 34,12.9 22.2,24.7"></polyline>
		</g>
	</svg>
);

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  return (
    <Layout pageDescription={frontmatter.description}>
      <IndexPageTemplate
        title={frontmatter.title}
        haarden={frontmatter.haarden}
        certificaten={frontmatter.certificaten}
        fotoHans={frontmatter.fotohans1}
      />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        description
        fotohans1 {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 80) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        haarden {
          image {
            childImageSharp {
              fluid(maxWidth: 1920, quality: 60) {
                ...GatsbyImageSharpFluid
              }
              original {
                width
                height
              }
            }
          }
          text
        }
        certificaten {
          image {
            childImageSharp {
              fluid(maxWidth: 1920, quality: 60) {
                ...GatsbyImageSharpFluid
              }
              original {
                width
                height
              }
            }
          }
          text
        }
      }
    }
  }
`;
